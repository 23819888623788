import { ComponentStyleConfig } from '@chakra-ui/react'

export const Drawer: ComponentStyleConfig = {
    parts: ['dialog', 'header', 'body'],
    sizes: {
        xl: {
            dialog: {
                maxW: '1184px',
            },
        },
    },
    variants: {
        dashboardMenu: {
            dialog: {
                maxW: '242px',
            },
        },
    },
}
