import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react'

// https://github.com/chakra-ui/chakra-ui/issues/4695
const $arrowBg = cssVar('popper-arrow-bg')

// baseStyle
const baseStyle = defineStyle({
    bgColor: 'green.light',
    [$arrowBg.variable]: 'colors.green.light',
})

const variants = {
    green: defineStyle(({}) => {
        return {
            bgColor: 'green.default',
            [$arrowBg.variable]: 'colors.green.default',
            color: 'white',
            fontWeight: '200',
        }
    }),
}

export const Tooltip = defineStyleConfig({
    baseStyle,
    variants,
})
